
import CloudFun, {
  defineComponent,
  ref,
  Condition,
  Operator
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import { VueUploadItem } from "vue-upload-component";

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});

    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions: GridOptions = {
      title: "人流計數主機",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "人流計數主機清單",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "人流計數主機清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "Id",
          title: "編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "filter-name" }
        },
        {
          field: "HotspotName",
          title: "地點",
          showHeaderOverflow: true,
          showOverflow: true
        },
        {
          field: "Type",
          title: "類型",
          showHeaderOverflow: true,
          showOverflow: true,
          formatter: ({ cellValue }) =>
            model
              ? Object.values(model.enums.ApiType).find(
                e => e.Value === cellValue
              )?.Name
              : undefined
        },
        {
          field: "Status",
          title: "狀態",
          showHeaderOverflow: true,
          showOverflow: true,
          formatter: ({ cellValue }) =>
            model
              ? Object.values(model.enums.DeviceStatus).find(
                e => e.Value === cellValue
              )?.Name
              : undefined
        }
      ],
      promises: {
        query: model
          ? params =>
            new Promise(resolve => {
              model.dispatch("ivar/query", params).then(async ivars => {
                const hotspots = await model.dispatch("hotspot/query", {
                  condition: new Condition(
                    "Id",
                    Operator.In,
                    ivars.data.map((e: any) => e.HotspotId)
                  )
                });
                ivars.data.forEach((e: any) => {
                  e.HotspotName = hotspots.find(
                    (h: any) => h.Id === e.HotspotId
                  )?.Name;
                });
                resolve(ivars);
              });
            })
          : undefined,
        queryAll: model
          ? () =>
            new Promise(resolve => {
              model.dispatch("ivar/query").then(async ivars => {
                const hotspots = await model.dispatch("hotspot/query", {
                  condition: new Condition(
                    "Id",
                    Operator.In,
                    ivars.data.map((e: any) => e.HotspotId)
                  )
                });
                ivars.data.forEach((e: any) => {
                  e.HotspotName = hotspots.find(
                    (h: any) => h.Id === e.HotspotId
                  )?.Name;
                });
                resolve(ivars);
              });
            })
          : undefined,
        save: model ? params => model.dispatch("ivar/save", params) : undefined
      },
      modalConfig: { width: 600, showFooter: true }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 110,
      titleAlign: "right",
      span: 3,
      items: [
        {
          field: "TempId",
          title: "編號",
          span: 12,
          // itemRender: { name: "$input", props: { placeholder: "編號", disabled: true } }
          slots: { default: "column-id" }
        },
        {
          field: "Name",
          title: "名稱",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "名稱" } }
        },
        {
          field: "Type",
          title: "類型",
          span: 12,
          itemRender: {
            name: "$select",
            options: model
              ? Object.values(model.enums.ApiType).map(e => {
                return { label: e.Name, value: e.Value };
              })
              : []
          }
        },
        {
          field: "Status",
          title: "狀態",
          span: 12,
          itemRender: {
            name: "$select",
            options: model
              ? Object.values(model.enums.DeviceStatus).map(e => {
                return { label: e.Name, value: e.Value };
              })
              : []
          }
        },
        {
          field: "HotspotId",
          title: "地點",
          span: 24,
          slots: { default: "column-hotspot-id" }
        },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          itemRender: { name: "$textarea" }
        }
      ],
      rules: {
        TempId: [{ required: true }],
        Name: [{ required: true }],
        Status: [{ required: true }],
        Type: [{ required: true }],
        HotspotId: [{ required: true }]
      }
    };

    // if (CloudFun.current?.policy?.isApproved(CloudFun.current.user!, "Administrator")) {
    //   formOptions.items?.splice(4, 0,
    //     { field: "IsCountCapacity", title: "是否計算人數", span: 12, itemRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否' } } },
    //     { field: "EnterWeight", title: "進入人數權重", span: 12, itemRender: { name: "$input" } },
    //     { field: "LeaveWeight", title: "離開人數權重", span: 12, itemRender: { name: "$input" } },
    //   )
    // }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇主機",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("hotspot/find", value), // eslint-disable-line
        query: params => model!.dispatch("hotspot/query", params) // eslint-disable-line
      }
    };

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      selectorOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    }
  }
});
